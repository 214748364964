import { template as template_58f33558135242d7b73491d082908de3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_58f33558135242d7b73491d082908de3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
