import { template as template_0c2823cff86742f090323e7f0c2fe616 } from "@ember/template-compiler";
const FKLabel = template_0c2823cff86742f090323e7f0c2fe616(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
