import { template as template_ce24560292a742f1839c9429f6a3a7bd } from "@ember/template-compiler";
const FKText = template_ce24560292a742f1839c9429f6a3a7bd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
