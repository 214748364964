import { template as template_e3306b0cfb314b53974c15ce298e25fc } from "@ember/template-compiler";
const SidebarSectionMessage = template_e3306b0cfb314b53974c15ce298e25fc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
