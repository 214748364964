import { template as template_ec794b5233884593a5bc112ee2121e48 } from "@ember/template-compiler";
const WelcomeHeader = template_ec794b5233884593a5bc112ee2121e48(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
